<template>
  <div class="containerIndex">
    <!-- header  大于1366px时的 -->
    <!-- <div class="navBox trans" :class="{ active: isactive == true }" > -->
    <div class="navBox" :class="navBarFixed == true ? 'navBoxactive' : ''">
      <div class="navWrap wrap clearfloat" style="position: relative">
        <div class="topwrap fl">
          <div class="indexLogo">
            <a href="/"><img :src="logo" alt="" /></a>
          </div>
        </div>
        <div class="fr nav_box_fr">
          <div class="nav_box">
            <ul class="nav_box_list clearfloat">
              <li style="border-left: none">
                <div class="nav_grop">
                  <!-- <h2><router-link to="/">首页</router-link></h2> -->
                  <h2><a href="/">网站首页</a></h2>
                </div>
              </li>
              <li
                v-for="(item, index) in navList"
                :key="index"
                :class="{ borderR: index == navList.length - 1 }"
                @mouseenter="navShow(item.channelId)"
                @mouseleave="navClose()"
              >
                <div class="nav_grop">
                  <!-- <h2 v-if="item.entTChannelUuid == '1252'">
                    <a :href="item.child[0].child[0].catalog == 1 ? `/listPage/${item.child[0].child[0].entTChannelUuid}` 
                       : item.child[0].child[0].catalog == 2 ? `/list/${item.child[0].child[0].entTChannelUuid}` 
                       : `/listPic/${item.child[0].child[0].entTChannelUuid}`">
                      {{ item.name }}
                    </a>
                  </h2> -->
                  <!-- 一级菜单 -->
                  <h2>
                    <!-- 二级菜单有url，则跳转第一个二级菜单的url -->
                    <a
                      v-if="
                        item.child.length &&
                        item.child[0].url &&
                        item.child[0].url !== ''
                      "
                      target="_blank"
                      :href="item.child[0].url"
                    >
                      {{ item.name }}
                    </a>
                    <!-- 二级菜单没有url/url为空，则跳转本地页面 && 非品牌文化 -->
                    <!-- <a v-else-if="(item.child.length && (!item.child[0].url || item.child[0].url == '') && item.entTChannelUuid !== '0225' )" -->
                    <a
                      v-else-if="
                        item.child.length &&
                        (!item.child[0].url || item.child[0].url == '')
                      "
                      :href="
                        item.catalog == 1
                          ? `/listPage/${item.child[0].entTChannelUuid}`
                          : item.catalog == 2
                          ? `/list/${item.child[0].entTChannelUuid}`
                          : `/listPic/${item.child[0].entTChannelUuid}`
                      "
                      target="_blank"
                    >
                      {{ item.name }}
                    </a>
                    <!-- 二级菜单没有url/url为空，则跳转本地页面 && 品牌文化单独跳转 -->
                    <!-- <a
                      v-else-if="
                        item.child.length && item.entTChannelUuid == '0225'
                      "
                      :href="`/list/022500010001`"
                      target="_blank"
                    >
                      {{ item.name }}
                    </a> -->
                    <!-- 没有二级菜单 -->
                    <a
                      v-else-if="!item.child.length"
                      :href="
                        item.catalog == 1
                          ? `/listPage/${item.entTChannelUuid}`
                          : item.catalog == 2
                          ? `/list/${item.entTChannelUuid}`
                          : `/listPic/${item.entTChannelUuid}`
                      "
                      target="_blank"
                    >
                      {{ item.name }}
                    </a>

                    <!-- 三级菜单有url，则跳转第一个二级菜单的url -->
                    <!-- <a
                      v-else-if="
                        item.child.child[0].length &&
                        item.child[0].child[0].url &&
                        item.child[0].child[0].url !== ''
                      "
                      target="_blank"
                      :href="item.child[0].child[0].url"
                    >
                      {{ item.name }}
                    </a> -->
                    <!-- 三级菜单没有url/url为空，则跳转本地页面 -->
                    <!-- <a
                      v-else-if="
                        item.child[0].child[0].length &&
                        (!item.child[0].child[0].url ||
                          item.child[0].child[0].url == '')
                      "
                      :href="
                        item.child[0].child[0].catalog == 1
                          ? `/listPage/${item.child[0].child[0].entTChannelUuid}`
                          : item.child[0].child[0].catalog == 2
                          ? `/list/${item.child[0].child[0].entTChannelUuid}`
                          : `/listPic/${item.child[0].child[0].entTChannelUuid}`
                      "
                      target="_blank"
                    >
                      {{ item.name }}
                    </a> -->
                  </h2>

                  <el-collapse-transition>
                    <!-- 二级菜单 -->
                    <div class="nav_down" v-show="navDown == item.channelId">
                      <!-- 有二级菜单则下拉 -->
                      <div
                        class="nav_down_wrap clearfloat"
                        v-show="item.child.length && item.child.length != 0"
                      >
                        <p v-for="(item1, index1) in item.child" :key="index1">
                          <!-- 无三级菜单 && 二级菜单无url/二级菜单url为空，则条本地 -->
                          <a
                            v-if="
                              !item1.child.length &&
                              (!item1.url || item1.url == '')
                            "
                            :href="
                              item1.catalog == 1
                                ? `/listPage/${item1.entTChannelUuid}`
                                : item1.catalog == 2
                                ? `/list/${item1.entTChannelUuid}`
                                : `/listPic/${item1.entTChannelUuid}`
                            "
                            target="_blank"
                          >
                            {{ item1.name }}
                          </a>
                          <!-- 无三级菜单 && （二级菜单有url && 二级菜单url不为空），则跳转二级菜单url -->
                          <a
                            v-if="
                              !item1.child.length &&
                              item1.url &&
                              item1.url !== ''
                            "
                            :href="item1.url"
                            target="_blank"
                          >
                            {{ item1.name }}
                          </a>
                          <!-- 有三级，则跳三级的第一个url -->
                          <a
                            v-if="
                              item1.child.length &&
                              item1.child[0].url &&
                              item1.child[0].url !== ''
                            "
                            :href="item1.url"
                            target="_blank"
                          >
                            {{ item1.name }}
                          </a>
                          <!-- 有三级，三级url为空，跳本地 -->
                          <!-- <a v-if="item1.child.length && (!item1.child[0].url || item1.child[0].url == ''|| item1.child[0].url == null ) && item1.entTChannelUuid !='011500050003'" -->
                          <a
                            v-if="
                              item1.child.length &&
                              (!item1.child[0].url ||
                                item1.child[0].url == '' ||
                                item1.child[0].url == null)
                            "
                            :href="
                              item1.catalog === 1
                                ? `/listPage/${item1.child[0].entTChannelUuid}`
                                : item1.catalog === 2
                                ? `/list/${item1.child[0].entTChannelUuid}`
                                : `/listPic/${item1.child[0].entTChannelUuid}`
                            "
                            target="_blank"
                          >
                            {{ item1.name }}
                          </a>
                          <!-- 特殊情况跳转 -->
                          <!-- <a v-if="item1.child.length && (!item1.child[0].url || item1.child[0].url == '') && item1.entTChannelUuid =='011500050003'"
                            :href="`/list/011500050003`"
                             target="_blank">
                            {{ item1.name }}
                          </a> -->
                        </p>
                      </div>
                    </div>
                  </el-collapse-transition>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="mp3icon" style="position: absolute; top: 15px; right: 15px">
        <!-- 导入音乐 -->
        <!-- <audio
          :src="musicList0"
          ref="music"
        ></audio> -->
        <audio ref="audio" @ended="playNext" :src="musicList0[0]"></audio>
        <!-- 音乐图标 -->
        <img
          v-if="isSpin"
          :style="{ 'animation-play-state': animat }"
          @click="spinSwitch()"
          src="../assets/image/mp3.png"
          style="width: 30px"
        />
        <img
          v-else
          :style="{ 'animation-play-state': animat }"
          @click="spinSwitch()"
          src="../assets/image/mp3.png"
          style="width: 30px"
        />
      </div>
    </div>
    <!-- header  小于1366px时的 -->
    <div id="topPart">
      <div class="top">
        <div class="topLogo">
          <div class="topLogoMain wrap">
            <div class="topLogoMainLeft">
              <!-- <a href="/" class="logoImgPc"><img src="../../assets/image/Nlogo.png" class="Nlogo"/></a>
              <a href="/" class="logoImgMobile"><img src="../../assets/image/Nlogo_mobile.png" class="Nlogo"/></a> -->
              <a href="/"><img :src="logo" /></a>
            </div>
            <div class="topLogoMainRight">
              <div class="header_search">
                <div>
                  <el-input
                    v-model="searchValue"
                    placeholder="请输入标题"
                    style="width: 350px"
                  ></el-input>
                  <el-button
                    type="submit"
                    class="btn"
                    style="background-color: #0d65af; border-radius: 0px"
                    @click="searchSubmit"
                    >搜索
                  </el-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="topMenu" style="position: relative;">
          <div class="topMenuMain wrap">
            <el-menu
              class="el-menu-demo"
              background-color="rgba(0,0,0,0)"
              text-color="#ffffff"
              active-text-color="rgb(108, 171, 231)"
              mode="horizontal"
              :router="false"
            >
              <div style="display: inline-block">
                <li class="el-menu-item2">
                  <a style="color: #fff" href="/"> 网站首页 </a>
                </li>
                <span class="span1"></span>
              </div>
              <div
                style="display: inline-block"
                :class="{ active: active == item.entTChannelUuid }"
                v-for="(item, index) in navList"
                :key="index + '001'"
              >
                <el-menu-item :index="item.index">
                  <a
                    v-if="
                      item.child.length &&
                      item.child[0].url &&
                      item.child[0].url !== ''
                    "
                    style="color: #fff"
                    target="_blank"
                    :href="item.child[0].url"
                  >
                    {{ item.name }}
                  </a>
                  <a
                    v-if="
                      item.child.length &&
                      (!item.child[0].url || item.child[0].url == '')
                    "
                    style="color: #fff"
                    :href="
                      item.catalog == 1
                        ? `/listPage/${item.child[0].entTChannelUuid}`
                        : item.catalog == 2
                        ? `/list/${item.child[0].entTChannelUuid}`
                        : `/listPic/${item.child[0].entTChannelUuid}`
                    "
                  >
                    {{ item.name }}
                  </a>
                  <a
                    v-if="!item.child.length"
                    style="color: #fff"
                    :href="
                      item.catalog == 1
                        ? `/listPage/${item.entTChannelUuid}`
                        : item.catalog == 2
                        ? `/list/${item.entTChannelUuid}`
                        : `/listPic/${item.entTChannelUuid}`
                    "
                  >
                    {{ item.name }}
                  </a>
                </el-menu-item>
                <span class="span1" v-if="index < navList.length - 1"></span>
                <!-- <span class="span2"></span> -->
              </div>
            </el-menu>
            <div
                class="mp3icon"
                style="position: absolute; top: 12px; right: 0px"
              >
                <!-- <div class="mp3icon"> -->
                <!-- 导入音乐 -->
                <!-- <audio
                      :src="musicList0"
                      ref="music"
                    ></audio> -->
                <audio
                  ref="audio"
                  @ended="playNext"
                  :src="musicList0[0]"
                ></audio>
                <!-- 音乐图标 -->
                <img
                  v-if="isSpin"
                  :style="{ 'animation-play-state': animat }"
                  @click="spinSwitch()"
                  src="../assets/image/mp3white.png"
                  style="width: 30px"
                />
                <img
                  v-else
                  :style="{ 'animation-play-state': animat }"
                  @click="spinSwitch()"
                  src="../assets/image/mp3white.png"
                  style="width: 30px"
                />
              </div>
          </div>
        </div>
      </div>
    </div>
    <!-- main -->
    <div class="mainBox containerMain" :style="containerStyle">
      <router-view></router-view>
    </div>
    <!-- footer -->
    <div class="footerBox">
      <!-- <div class="wrap"> -->
      <el-row style="margin: 0 auto" class="wrap">
        <!-- <el-row type="flex" justify="start" style="margin: 0;"> -->
        <!-- 第一栏 -->
        <el-col :xs="24" :sm="24" :md="9" class="el-col-first">
          <div class="footerLeft">
            <div>
              <img src="../assets/image/footer1.png" />
              <h2>关注微信公众号</h2>
            </div>
            <div>
              <img src="../assets/image/footer2.png" />
              <h2>关注抖音号</h2>
            </div>
            <div>
              <img src="../assets/image/footer3.png" />
              <h2>关注视频号</h2>
            </div>
          </div>
        </el-col>
        <!-- 第二栏 -->
        <el-col :xs="24" :sm="24" :md="10" class="el-col-second">
          <div class="footerMid">
            <h2>联系我们</h2>
            <p>咨询热线：0931-7540123</p>
            <p>邮箱地址：gsjs4@163.com</p>
            <p>公司地址：甘肃省兰州市西固区福利东路555号</p>
          </div>
        </el-col>
        <!-- 第三栏 -->
        <el-col :xs="24" :sm="24" :md="5" class="el-col-third">
          <div class="footerRight">
            <p>传真号码：0931-7540123</p>
            <!-- <p>工业和信息化部ICP/IP地址/域名信息备案管理系统</p> -->
            <p>
              <a
                href="https://beian.miit.gov.cn"
                target="_blank"
                style="color: #fff"
                >陇ICP备17003292号-1</a
              >
            </p>
            <p>
              甘公网安备
              <a
                href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=62010402000132"
                target="_blank"
                style="color: #fff"
                >62010402000132号</a
              >
            </p>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
// import { getNavChannel,getAdvert } from '@/api/common'
import { getNavChannel } from "@/api/common";
export default {
  name: "",
  data() {
    return {
      activeNav: 0,
      navBarFixed: false, //导航吸顶
      navSubList: [],
      containerStyle: {},
      viewImgTop: true,
      content: "",
      viewSearch: false,
      searchValue: "",
      logo: require("@/assets/img/logo_lj.png"),
      active: 0,
      navList: [],
      navDown: 0,
      musicList0: [
        require("../assets/video/司歌.mp3"),
        require("../assets/video/四建欢迎你.mp3"),
        require("../assets/video/幸福里的思念.mp3"),
        require("../assets/video/打卡四建.mp3"),
      ],
      index: 0,
      musicList: [
        {
          // titile: "四建欢迎你",
          mp3: require("../assets/video/四建欢迎你.mp3"),
        },
        {
          // titile: "司歌",
          mp3: require("../assets/video/司歌.mp3"),
        },
        {
          // titile: "幸福里的思念",
          mp3: require("../assets/video/幸福里的思念.mp3"),
        },
        {
          // titile: "打卡四建",
          mp3: require("../assets/video/打卡四建.mp3"),
        },
      ],
      isPlaying: false,
      animat: "running",
      isSpin: true,
    };
  },
  mounted() {
    this.getPath();
    this.getNavChannel();
    this.active = this.$route.params.id;
    // 事件监听滚动条
    // window.addEventListener("scroll", this.scrollnav);
    //非home页nav吸顶
    // let url = this.$route.name;
    // if (url !== "home") {
    //   // this.changehomeTop();
    //   // this.changecontmargin();
    //   // this.changehomefooter();
    // }
    // if (url !== 'home') {
    // }
    // this.srollChannelTop()
    // this.musiccc()
    this.spinSwitch();
    // this.$refs.audio.src = this.musicList0[this.index];   //设置第一个路径
    // this.$refs.audio.play();   //直接播放第一个
  },
  destroyed() {
    // 移除事件监听
    window.removeEventListener("scroll", this.watchScroll);
  },
  created() {},
  watch: {
    $route: "getPath",
  },
  methods: {
    searchSubmit() {
      var _this = this;
      if (!_this.searchValue || _this.searchValue == "") {
        this.$message({
          message: "请输入关键词进行搜索！",
          type: "warning",
          offset: 200,
        });
      } else {
        window.location.href = `/search/${_this.searchValue}`;
        // window.open(`/search/${_this.searchValue}`)
      }
    },
    // 导航监听事件
    // scrollnav() {
    //   // 滚动距离
    //   var scrollTop =
    //     window.pageYOffset ||
    //     document.documentElement.scrollTop ||
    //     document.body.scrollTop;
    //   // 容器高度
    //   var offsetTop = document.querySelector(".navBox").offsetHeight;
    //   // console.log("scrollTop=>", scrollTop, "  offsetTop=>", offsetTop);
    //   //  当滚动超过 offsetTop 时，实现吸顶效果
    //   if (scrollTop > offsetTop) {
    //     this.navBarFixed = true;
    //   } else {
    //     this.navBarFixed = false;
    //   }
    // },
    //非home页nav吸顶
    // changehomeTop() {
    //   var navBox = document.getElementsByClassName("navBox");
    //   navBox[0].style.position = "absolute"; //属性修改
    //   navBox[0].style.top = "0"; //属性修改
    // },
    // 非home页内容marging
    // changecontmargin() {
    //   var containerMain = document.getElementsByClassName("containerMain");
    //   containerMain[0].style.margin = "60px 0 0 0"; //属性修改
    // },
    //非home页footer
    // changehomefooter() {
    //   var footerBox = document.getElementsByClassName("footerBox");
    //   footerBox[0].style.margin = "0"; //属性修改
    // },
    // srollimg() {
    //   var _this = this
    //   let data = {
    //     applicationUuid: _this.webId,
    //     advertType: 4
    //   }
    //   getAdvert(data).then(res => {
    //     const { code, data } = res
    //     if (code === 1 && data) {
    //       data.length && (_this.content = data[0])
    //       // this.$nextTick(() => {
    //       //   this.srollTop()
    //       // })
    //     }
    //   })
    // },

    // srollTop() {
    //   var _this = this;
    //   // var heightT;
    //   // setTimeout(() => {
    //   //   heightT = this.$refs.topImg.offsetHeight;
    //   //   _this.containerStyle = {
    //   //     marginTop: heightT + "px",
    //   //   };
    //   // }, 0);
    //   // 500
    //   setTimeout(() => {
    //     _this.containerStyle = {
    //       marginTop: 0,
    //     };
    //   }, 0);
    //   // 3000
    //   setTimeout(() => {
    //     _this.viewImgTop = false;
    //   }, 0);
    //   // 6000
    // },

    // srollChannelTop() {
    //   var _this = this;
    //   let url = this.$route.name
    //   if (url == 'home') {
    //     setTimeout(() => {
    //       _this.activeNav = 1;
    //     }, 0);
    //     // 4000
    //   }
    //   else{
    //     _this.activeNav = 1;
    //   }
    // },

    // searchBtn(val) {
    //   var _this = this;

    //   _this.viewSearch = val;
    // },

    // 获取导航
    getNavChannel() {
      var _this = this;
      let data = {
        applicationUuid: _this.webId,
      };
      getNavChannel(data).then((res) => {
        const { code, data } = res;
        if (code === 1 && data) {
          _this.navList = data.slice(0, 7);
          // console.log(123456,_this.navList)
        }
      });
    },
    // 二级导航展开
    navShow(d) {
      let _this = this;
      // _this[d] = true
      _this.navDown = d;
    },
    // 二级导航收起
    navClose() {
      let _this = this;
      // _this[d] = false
      _this.navDown = 0;
    },
    haddleNav(index, params) {
      var _this = this;
      _this.active = index;
      _this.$router.push(params.url);
    },
    getPath() {
      var _this = this;
      _this.active = _this.$route.meta.index;
    },
    // musiccc() {
    //   // 音乐
    //   var music = document.getElementById("music"); //获取音乐
    //   //获取音乐图标
    //   var musicico = document.getElementById("musicico"); // eslint-disable-line no-unused-vars
    //   var tem = true; //控制音乐是否播放的变量
    //   if (tem == false) {
    //     music.play(); //播放
    //     tem = true;
    //     document.getElementById("musicico").style.animationPlayState =
    //       "running"; //播放音乐图标
    //   } else {
    //     music.pause(); //暂停音乐
    //     tem = false;
    //     document.getElementById("musicico").style.animationPlayState = "paused"; //暂停音乐图标
    //   }
    // },
    // 图标旋转 + 播放暂停
    spinSwitch() {
      // var audio = document.getElementById("music");  //获取音乐
      this.isSpin = !this.isSpin;
      this.animat = this.animat === "running" ? "paused" : "running";
      if (this.isPlaying == false) {
        this.$refs.audio.pause();
        // audio.pause();
        this.isPlaying = true;
      } else if (this.isPlaying == true) {
        this.$refs.audio.play();
        // audio.play();
        this.isPlaying = false;
      }
    },
    playNext() {
      if (this.index < this.musicList0.length - 1) {
        this.index++; //播放索引+1
        this.$refs.audio.src = this.musicList0[this.index]; //下一个
        this.$refs.audio.play();
      } else if (this.index == this.musicList0.length - 1) {
        this.index = 0;
        this.$refs.audio.src = this.musicList0[this.index]; //下一个
        this.$refs.audio.play();
      }
    },
  },
};
</script>
<style>
#topPart {
  display: none;
}
/* > 1300菜单 */
.navBox {
  height: 60px;
  /* min-width: 1280px; */
  background-color: rgba(255, 255, 255, 0.7);
  /* position: fixed; */
  /* position: relative; */
  /* top: 70px;
  left: 0; */
  width: 100%;
  /* z-index: 9999; */
}
.navBoxactive {
  position: fixed;
  top: 0;
}
/* .navcss{top:0} */
.navWrap {
  height: 100%;
}
.topwrap {
  height: 100%;
}
.indexLogo {
  height: 100%;
  width: 290px;
  overflow: hidden;
}
.indexLogo img {
  width: 100%;
}
.nav_box_list li {
  float: left;
  height: 45px;
  line-height: 33px;
  /* margin: 0 30px; */
  padding: 15px 30px 0px 30px;
  transition: all 0.5s ease;
}
.nav_box_list li h2 a {
  display: block;
  font-size: 16px;
  position: relative;
  transition: all 0.5s ease;
  /* padding: 0 4px; */
}
.nav_down {
  position: absolute;
  z-index: 30;
  width: 124px;
  background-color: rgba(0, 98, 177, 0.4);
  color: #fff;
  margin-left: -30px;
  /* left: 0; */
  top: 60px;
  text-align: center;
}
.nav_box_list li:hover {
  background-color: rgba(0, 98, 177, 0.4);
}
.nav_box_list li:hover h2 a {
  color: #fff;
}
.nav_down_wrap {
  padding: 8px 0;
}
.nav_down a {
  display: block;
  color: #fff;
  /* padding: 0px 0; */
}
.nav_down a:hover {
  font-weight: bold;
}
.nav_down p {
  /* display: inline-block; */
  font-size: 16px;
  margin: 0 14px;
  padding: 6px 0;
}
/* < 1300首页搜索 */
.topLogo {
  height: auto;
}
.topLogoMain {
  height: 100%;
  overflow: hidden;
  margin: 0 auto;
}
.topLogoMainLeft {
  height: 80px;
  /* background-color: #8eb100; */
  width: 40%;
  float: left !important;
}
.topLogoMainLeft img {
  height: 100%;
}
.topLogoMainRight {
  /* background-color: #00b109; */
  height: 100%;
  width: 50%;
  float: right !important;
  overflow: hidden;
}
.header_search {
  /* background-color: #0020b1; */
  width: 100%;
  height: 38px;
  overflow: hidden;
  margin-top: 18px;
}
.header_search input {
  width: 350px;
  height: 38px;
  overflow: hidden;
  outline: none;
  box-shadow: none;
  line-height: 38px;
  font-size: 14px;
  color: #585858;
  border-radius: 0px;
  border: solid 1px #0d65af;
}
.btn {
  display: inline-block;
  height: 38px;
  line-height: 38px;
  padding: 0px 12px;
  color: #fff;
  white-space: nowrap;
  text-align: center;
  font-size: 14px;
  border: none;
  cursor: pointer;
  vertical-align: top;
  width: 52px;
}
/* < 1300导航菜单 */
.topMenu {
  height: 50px;
  background-color: #0162b3;
  overflow: hidden;
  border-bottom: 1px #ffffff solid;
  position: relative;
  z-index: 100;
  /* top: -45px; */
  top: 0px;
}
.topMenuMain,
.topMenuMain ul {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  /* background-color: #33b301; */
  text-align: center;
  overflow-y: hidden;
}
.topMenuMain ul div {
  height: 100%;
}
/* 网站首页 */
.el-menu-item2 {
  width: 148px;
  height: 50px;
  /* background-color: #b30187; */
  line-height: 50px;
  text-align: center;
  float: left;
  color: #ffffff;
  display: block;
  font-size: 16px;
  font-weight: 600;
}
/* 菜单 */
.topMenuMain.active .el-menu-item {
  background-color: rgba(7, 6, 63, 0.425) !important;
}
.el-menu-item:hover {
  background-color: rgba(7, 6, 63, 0.425) !important;
}
.el-menu-item {
  width: 148px;
  /* background-color: #a7b301; */
  height: 50px;
  line-height: 50px;
  text-align: center;
  float: left;
  color: #ffffff;
  display: block;
  font-size: 16px;
  font-weight: 600;
}
.el-menu--horizontal > .el-menu-item {
  float: left;
  height: 60px;
  line-height: 52px;
  margin: 0;
  border-bottom: 2px solid transparent;
}
.el-menu .span1 {
  width: 2px;
  height: 50px;
  background: url(../assets/image/menu_line.jpg) center center no-repeat;
  display: inline-block;
}
/* 底部 */
.footerBox {
  /* margin-top: -60px; */
  padding: 40px 0 25px;
  background-color: #0062b1;
  color: #fff;
  font-size: 17px;
  width: 100%;
}
/* .footerBoxcss {
  margin-top: 0;
  padding: 40px 0 25px;
  background-color: #0062b1;
  color: #fff;
  font-size: 17px;
  width: 100%;
} */
.footerLeft {
  text-align: center;
  height: auto;
}
.footerLeft div {
  padding-top: 18px;
  float: left;
  width: 33%;
  height: auto;
  /* height: 140px; */
}
.footerLeft img {
  width: 80%;
  height: auto;
  margin-bottom: 15px;
}
.footerMid {
  /* background-color: yellow; */
}
.footerMid h2,
.footerMid p {
  padding-bottom: 22px;
  padding-left: 80px;
  /* text-align: center; */
  /* padding-left: 5px; */
}
.footerRight {
  /* background-color: red; */
  padding-top: 46px;
}
.footerRight p {
  /* text-align: center; */
  padding-bottom: 22px;
}
.containerMain {
  -moz-transition: all 1s ease-in;
  -webkit-transition: all 1s ease-in;
  -o-transition: all 1s ease-in;
  transition: all 1s ease-in;
  z-index: 10;
}
/* 音乐图标 */
/* 音乐播放和暂停 */
/* img#musicico{ */
/* music是下方的动画名称 */
/* animation-name: music; 
	animation-timing-function: linear;  */
/* 3秒旋转完一圈 */
/* animation-duration: 3s; */
/* 旋转无数次 */
/* animation-iteration-count: infinite;
} */
/* 实现动画的旋转 */
/* @keyframes music{
	0%{
		transform: rotate(0deg);
	}
	50%{
		transform: rotate(180deg);
	}
	100%{
		transform: rotate(365deg);
	}
} */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.mp3icon img {
  animation: spin 4s infinite linear;
}
/* 适配 */
@media (max-width: 1366px) {
  .topLogoMainLeft {
    text-align: right;
  }
  .footerMid h2,
  .footerMid p {
    padding-left: 10px;
  }
  .navWrap {
    display: none;
  }
  #topPart {
    display: block;
  }
  .containerMain {
    margin-top: 60px;
  }
  .navBox {
    display: none;
  }
  .navBox {
    min-width: auto;
    height: auto;
    background-color: #fff;
    position: fixed;
    top: -200px;
    left: 0;
    width: 100%;
    z-index: 9999;
  }
  .nav_box_fr {
    height: auto;
    float: none;
    text-align: center;
    height: 50px;
    width: 100%;
    overflow: hidden;
  }
  .nav_box {
    padding-top: 0px;
  }
  .nav_box_list {
    text-align: center;
    width: auto;
    margin: 0 auto;
  }
  .el-menu-item {
    width: 120px;
  }
  .el-menu-item2 {
    width: 120px;
  }
  .topLogoMain {
    width: 1000px;
  }
  .footerBox {
    margin-top: 0px;
  }
}
@media (max-width: 992px) {
  #topPart {
    display: block;
    background-color: #fff;
  }
  .footerBox .el-col {
    width: 65%;
    float: none;
    margin: 0 auto 0px;
    height: auto;
  }
  .footerMid h2,
  .footerMid p {
    padding-left: 0px;
  }
  .footerRight {
    padding-top: 0px;
  }
  .navBox {
    display: none;
    min-width: auto;
    height: auto;
    background-color: #fff;
    position: fixed;
    top: -200px;
    left: 0;
    width: 100%;
    z-index: 9999;
  }
  .nav_box_fr {
    height: auto;
    float: none;
    text-align: center;
    height: 50px;
    width: 100%;
    overflow: hidden;
  }
  .nav_box {
    padding-top: 0px;
  }
  .nav_box_list {
    text-align: center;
    width: auto;
    margin: 0 auto;
  }
  .topLogoMain {
    width: 850px;
  }
  .header_search {
    width: 80%;
  }
  .header_search .el-input {
    width: 80% !important;
  }
  .header_search input {
    width: 100%;
  }
  .el-menu-item {
    width: 100px;
    padding-left: 0;
    padding-right: 0;
  }
  .el-menu-item2 {
    width: 100px;
    padding-left: 0;
    padding-right: 0;
  }
  .el-menu .span1,
  .el-menu .span2 {
    display: none;
  }
}
@media (max-width: 850px) {
  .topLogoMainLeft img {
    width: 90%;
  }
  .header_search {
    width: 90%;
    margin-top: 12px;
  }
  .el-menu-item {
    width: 88px;
  }
  .el-menu-item2 {
    width: 88px;
  }
  .topLogoMain {
    width: 768px;
  }
  .footerBox .el-col {
    width: 65%;
    float: none;
    margin: 0 auto 0px;
    height: auto;
  }
  .footerRight {
    padding-top: 0px;
  }
}
@media (max-width: 768px) {
  .topMenu {
    top: 10px;
  }
  .navBox {
    display: none;
  }
  .navBox {
    min-width: auto;
    height: auto;
    background-color: #fff;
    position: fixed;
    top: -200px;
    left: 0;
    width: 100%;
    z-index: 9999;
  }
  .navBox.active {
    top: 0;
  }
  .header_search .el-input {
    width: 80% !important;
    height: 38px;
    line-height: 38px;
  }
  .nav_down {
    display: none;
  }
  .nav_box_fr {
    height: auto;
    float: none;
    text-align: center;
    height: 50px;
    width: 100%;
    overflow: hidden;
  }
  .nav_box_list li {
    float: left;
    line-height: 50px;
    margin: 0 13px;
  }
  .nav_box {
    padding-top: 0px;
    width: 100%;
    height: 50px;
    text-align: center;
    width: 100%;
    white-space: nowrap;
    overflow-y: hidden;
    overflow-x: auto;
  }
  .nav_box_list {
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
    border-bottom: none;
    padding: 0;
    height: 50px;
  }
  .footerBox {
    padding: 10px 0 10px;
  }
  .footerLeft {
    text-align: center;
    padding-top: 8px;
  }
  .footerLeft p a {
    line-height: 26px;
  }
  .footerLeft p {
    margin: 4px 0;
    font-size: 14px;
  }
  .footerRightBox h3 {
    margin-bottom: 0px;
  }
  .footerRightList li {
    line-height: 18px;
    margin: 6px 0;
    font-size: 14px;
  }
  .header_search .el-input {
    width: calc(100% - 54px) !important;
  }
  .el-menu.el-menu--horizontal {
    width: 90%;
    overflow-x: auto;
    white-space: nowrap;
    border-bottom: none;
    padding: 0;
    height: 50px;
  }
  .topMenuMain .el-menu-item {
    height: 50px !important;
    margin: 0 1px;
    line-height: 50px;
    border-radius: 4px;
  }
  .topMenuMain .el-menu-item2 {
    height: 50px !important;
    margin: 0 1px;
    border-radius: 4px;
    padding-bottom: 0px;
    line-height: 50px;
  }
  .el-menu .span1,
  .el-menu .span2 {
    display: none;
  }
  .topLogoMain {
    width: 100%;
  }
  .topLogoMainLeft {
    width: 100%;
    height: 60px;
    text-align: center;
  }
  .topLogoMainLeft img {
    height: 100%;
    width: 240px;
  }
  .topLogoMainRight {
    text-align: center;
    width: 100%;
  }
  .header_search {
    width: 90%;
    margin: auto;
  }
  .footerBox .el-row {
    left: 0;
  }
  .footerBox .el-col-second {
    clear: both;
    float: none;
    padding-top: 20px;
  }
}
</style>
